
import { defineComponent, computed, PropType } from 'vue'
import { Instance, Result, Team } from '../../../lib/apis'
import store from '../../../store'
export default defineComponent({
  props: {
    sortedInstance: {
      type: Array as PropType<Array<Instance>>,
      required: true
    },
    teamResults: { type: Array as PropType<Array<Result>>, required: true },
    team: { type: Object as PropType<Team>, required: true }
  },
  setup(props) {
    const maxScore = computed(() => store.getters.maxScore)
    const instanceStatusClass = (i: number) =>
      computed(() => {
        if (!props.sortedInstance) {
          return
        }
        switch (props.sortedInstance[i - 1].status) {
          case 'ACTIVE':
            return 'text-primary'

          case 'NOT_EXIST':
            return 'text-muted'

          case 'BUILDING':
            return 'text-info'

          default:
            return 'text-primary'
        }
      }).value
    const fetchInstanceInfo = () => store.dispatch.fetchInstances()
    return {
      instanceStatusClass,
      fetchInstanceInfo,
      maxScore
    }
  }
})
