
//TODO: ファイル分割する
import apis, { PostTeamRequest, Result, User, Message } from '../../../lib/apis'
import store from '../../../store'
import { computed, ref, watchEffect } from 'vue'
import InstanceInfo from './InstanceInfo.vue'
import Benchmark from './Benchmark.vue'
export default {
  components: {
    InstanceInfo,
    Benchmark
  },
  setup() {
    const teamMembers = ref<User[]>([])
    const teamName = ref('')
    const makeInstanceButton = ref(false)
    const modalText = ref('')
    const error = ref('')
    const largeModal = ref(false)
    const team = computed(() => store.state.Team)
    const user = computed(() => store.state.User)
    const showInfoModal = ref(false)
    const infoModalMessage = ref<{ better: string; message: string }>({
      better: '',
      message: ''
    })
    const lastResultMessages = computed(() => store.getters.lastResultMessages)
    const teamResults = computed(() =>
      store.state.Team && store.state.Team.results
        ? store.state.Team.results.sort((a, b) => {
            const datea = new Date(a.created_at)
            const dateb = new Date(b.created_at)
            return datea < dateb ? 1 : -1
          })
        : []
    )
    const sortedInstance = computed(() => {
      if (!store.state.Team || !store.state.Team.instance) {
        return []
      }
      const res = store.state.Team.instance
        .map(v => v)
        .sort((a, b) => {
          if (a.instance_number > b.instance_number) {
            return 1
          } else {
            return -1
          }
        })
      return res
    })

    const makeInstance = async () => {
      if (makeInstanceButton.value) {
        return
      }
      makeInstanceButton.value = true
    }
    const registerTeam = async () => {
      if (!store.state.User) {
        return
      } //TODO
      const group = await apis.meGroupGet().then(res => res.data)
      const req: PostTeamRequest = {
        name: teamName.value,
        group: group
      }
      const team = await apis.teamPost(req).then(res => res.data)
      const userReq: User = {
        name: store.state.User.name,
        screen_name: store.state.User.screen_name,
        team_id: team.ID
      }

      const user = await apis.userPost(userReq).then(res => res.data)
      await store.commit.setUser(user)
      await store.commit.setTeam(team)
    }
    const showModal = (data: Result) => {
      modalText.value = JSON.stringify(data, null, '  ')
      largeModal.value = true
    }
    const showInfo = (i: number) => {
      showInfoModal.value = true
      const betterize =
        '改善点：' +
        (teamResults.value && teamResults.value[i].betterize
          ? teamResults.value[i].betterize
          : '')

      infoModalMessage.value = {
        better: betterize,
        message: (teamResults.value && teamResults.value[i].messages
          ? teamResults.value[i].messages.map((a: Message) =>
              a.text ? a.text : ''
            )
          : []
        ).join('\n')
      }
    }

    watchEffect(async () => {
      if (!store.state.Team) {
        return [] as User[]
      }
      teamMembers.value = (await apis.teamIdMemberGet(store.state.Team.ID)).data
    })

    return {
      makeInstance,
      showModal,
      registerTeam,
      showInfo,
      teamMembers,
      error,
      team,
      user,
      lastResultMessages,
      teamName,
      sortedInstance,
      teamResults,
      showInfoModal,
      infoModalMessage
    }
  }
}
