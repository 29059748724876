
import { defineComponent, ref, computed, PropType } from 'vue'
import { AxiosError } from 'axios'
import apis, {
  Instance,
  PostBenchmarkRequest,
  Response,
  Result
} from '../../../lib/apis'
import store from '../../../store'
export default defineComponent({
  name: 'Benchmark',
  props: {
    sortedInstance: {
      type: Array as PropType<Array<Instance>>,
      required: true
    },
    teamResults: { type: Array as PropType<Array<Result>>, required: true }
  },
  setup(props) {
    const betterize = ref<string>('')
    const error = ref<string>('')
    const showOperationModal = ref(false)
    const operationInstanceNumber = ref(0)
    const waiting = ref(false)
    const team = computed(() => store.state.Team)
    const benchmarkButton = (i: number) =>
      computed(
        () =>
          store.state.Queue?.find(
            que => que.team.name === store.state.Team?.name
          ) ||
          (!props.sortedInstance
            ? false
            : props.sortedInstance[i - 1].status !== 'ACTIVE')
      ).value
    const benchmark = (id: number) => {
      if (benchmarkButton(id) || !store.state.Team) {
        return
      }
      const req: PostBenchmarkRequest = {
        betterize: betterize.value
      }
      apis
        .benchmarkTeamIdInstanceNumberPost(store.state.Team?.ID, id, req)
        .then(() => {
          betterize.value = ''
          store.dispatch.fetchData()
        })
        .catch((err: AxiosError<Response>) => {
          error.value = !err.response?.data.message
            ? ''
            : err.response?.data.message
        })
    }
    const instanceButton = (i: number) => {
      return computed(
        () =>
          (!props.sortedInstance || !props.sortedInstance[i - 1]
            ? false
            : props.sortedInstance[i - 1].status !== 'ACTIVE') &&
          (!props.sortedInstance || !props.sortedInstance[i - 1]
            ? false
            : props.sortedInstance[i - 1].status !== 'NOT_EXIST')
      ).value
    }
    const instanceButtonColor = (i: number) =>
      computed(() => {
        if (!props.sortedInstance) {
          return
        }
        switch (props.sortedInstance[i - 1].status) {
          case 'ACTIVE':
            return 'danger'

          case 'NOT_EXIST':
            return 'info'

          default:
            return 'info'
        }
      }).value
    const instanceButtonMessage = (i: number) =>
      computed(() => {
        if (!props.sortedInstance) {
          return
        }
        switch (props.sortedInstance[i - 1].status) {
          case 'ACTIVE':
            return `インスタンス${
              props.sortedInstance[i - 1].instance_number
            }を削除する`

          case 'NOT_EXIST':
            return `インスタンス${
              props.sortedInstance[i - 1].instance_number
            }を作成する`

          case 'BUILDING':
            return `作成中`

          default:
            return props.sortedInstance[i - 1].status
        }
      }).value
    const setOperationModal = (id: number) => {
      showOperationModal.value = true
      operationInstanceNumber.value = id
    }
    const operationInstance = (id: number) => {
      showOperationModal.value = false
      waiting.value = true
      if (
        instanceButton(id) ||
        !props.sortedInstance ||
        !props.sortedInstance[id - 1] ||
        !store.state.Team
      ) {
        return
      }
      switch (props.sortedInstance[id - 1].status) {
        case 'ACTIVE':
          apis
            .instanceTeamIdInstanceNumberDelete(store.state.Team?.ID, id)
            .then(() => {
              store.dispatch.fetchData()
              waiting.value = false
            })
            .catch((err: AxiosError<Response>) => {
              error.value = !err.response?.data.message
                ? ''
                : err.response?.data.message
              waiting.value = false
            })
          break
        case 'NOT_EXIST':
          apis
            .instanceTeamIdInstanceNumberPost(store.state.Team.ID, id)
            .then(() => {
              store.dispatch.fetchData()
              waiting.value = false
            })
            .catch((err: AxiosError<Response>) => {
              error.value = !err.response?.data.message
                ? ''
                : err.response?.data.message
              waiting.value = false
            })
          break
      }
    }

    return {
      betterize,
      operationInstanceNumber,
      showOperationModal,
      team,
      benchmarkButton,
      benchmark,
      instanceButton,
      instanceButtonColor,
      instanceButtonMessage,
      setOperationModal,
      operationInstance
    }
  }
})
